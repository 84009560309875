
import { WorkOrderConfigEntityModel, WorkOrderConfigQueryModel } from '@/entity-model/work-order-config-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/workorder-type/process-definition-relation`;

class WorkOrderConfigListService implements ICRUDQ<WorkOrderConfigEntityModel, WorkOrderConfigQueryModel> {
    async create(model: WorkOrderConfigEntityModel):Promise<WorkOrderConfigEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<WorkOrderConfigEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new WorkOrderConfigEntityModel().toModel(res);
    }

    async update(model: WorkOrderConfigEntityModel):Promise<WorkOrderConfigEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: WorkOrderConfigEntityModel):Promise<WorkOrderConfigEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderConfigQueryModel, page?: number, limit?: number):Promise<any> {
        // const url = `${URL_PATH}/list`;
        const url = `${URL_PATH}/query`;

        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        // const res = await post(url, {});

        res.items = _.map(res.items, item => item = new WorkOrderConfigEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new WorkOrderConfigEntityModel().toModel(item));
    }

    async deploy(id:string):Promise<any> {
        const url = `${URL_PATH}/synchronization/${id}`;
        const res = await get(url);
        return res;
    }
}

export default new WorkOrderConfigListService();
